.game img, .game svg {
  width: 12rem;
  height: 12rem;
}

.word {
  text-align: center;
  width: min(48rem, calc(100vw - 2rem));
  margin: 0 auto;
}

svg {
  cursor: pointer;
}

.word {
  height: 8rem;
  line-height: 8rem;
}

.score {
  padding-top: 0.5rem;
}

.score-section {
  display: inline-block;
}

.score-section + .score-section {
  padding-left: 1rem;
}

.score-section ul {
  display: inline-block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.score-section li {
  display: inline-block;
  padding: 0 0.25rem;
}

.score-section li.active, .score-section p.active {
  font-weight: bold;
  filter: none;
}

.score-ranges {
  list-style: none;
  padding: 0;
  margin: 0;
}

.score-section p, .final-score, .score-ranges span {
  display: block;
  margin: 0 auto;
  text-align: center;
  background-image: url('star.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.score-ranges span {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
}

.score-section p {
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  filter: grayscale(100%);
}

.final-score {
  width: 8rem;
  height: 8rem;
  line-height: 8rem;
  font-size: 4rem;
}

.mask {
  background: repeating-linear-gradient( -45deg, #ef476f, #ef476f 15px, transparent 15px, transparent 30px );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  transition: all 0.05s ease-in 0s;
}

.mask:after {
  content: '';
  background: white;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
  z-index: -1;
}

.mask.hidden {
  opacity: 0;
  transition: all 0.05s ease-in 0s;
}

.final-score {
  animation: zoom-in-zoom-out 0.25s ease-in;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1.25, 1.25);
  }
  100% {
    transform: scale(1, 1);
  }
}