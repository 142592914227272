* {
  box-sizing: border-box;
  text-align: center;
}

body {
  margin: 0;
  padding: 1rem 0;
}

.App {
  margin: 0 auto;
  max-width: 50rem;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem;
  height: calc(100vh - 2rem);
}

.App > div {
  display: grid;
  grid-template-columns: 1fr; 
  grid-template-rows: auto 1fr auto; 
  gap: 0px 0px; 
  height: 100%;
  padding: 0 1rem;
}

h1 {
  color: #073B4C;
  font-family: 'Satisfy', cursive;
}

header h1 {
  font-size: 1.5rem;
  margin: 0;
}

ul.action-list {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
}

.action-list li {
  margin: 0;
  padding: 0.5rem;
}

.action-list button {
  background-color: rgba(255, 255, 255, 0.6);
  border: solid 0.25rem #118AB2;
  color: #118AB2;
  font-family: inherit;
  font-size: 1.5rem;
  padding: 0.75rem;
  cursor: pointer;
  width: 100%;
  max-width: 20rem;
  border-radius: 0.5rem;
}

.action-list button:active {
  border-color: #27b9ea;
  color: #27b9ea;
}

.action-list button.primary {
  border-style: none;
  background-color: #118AB2;
  color: white;
}

.action-list button.primary:active {
  background-color: #27b9ea;
}

.action-list button.attention {
  border-style: none;
  background-color: #ef476f;
  color: white;
}

.action-list button.attention:active {
  background-color: #f7a3b7;
}

.action-list button:disabled, .action-list button:disabled:active {
  color: #AAA;
  border-color: #AAA;
  cursor: default;
}

.info {
  font-style: italic;
  font-size: 1rem;
  margin: 0;
}

.paragraphs main p, .privacy-settings main li {
  text-align: left;
  font-size: 1rem;
  margin: 0 0 1rem 0;
}

.paragraphs main ul {
  padding: 0 0 0 1rem;
}

.paragraphs main h2 {
  text-align: left;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}