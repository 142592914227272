.instructions p, .instructions h2, .instructions h3 {
  text-align: left;
}

.instructions img.screenshot {
  max-width: 100%;
  border: solid #AAA 2px;
  border-radius: 3px;
}

.instructions img.shape {
  max-width: 50%;
}

.instructions h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.instructions h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.instructions p {
  font-size: 1rem;
}

.instructions p, .instructions img {
  margin: 0 0 1rem 0;
}

.instructions .aside {
  border-left: solid 4px #118AB2;
  padding-left: 0.75rem;
}